'use client'


import React, {useEffect, useState} from 'react'
import {Tab} from '@headlessui/react'
import clsx from 'clsx'

import backgroundImage from './background-features.jpg'
import screenshotPrivacy from './screenshots/CompletePrivacy.png'
import screenshotAllMyAccounts from './screenshots/AllAccounts.png'
import screenshotMissingThings from './screenshots/NeverMissAThing.png'
import screenshotFast from './screenshots/LightningFast.png'
import {Container} from "./Container";
import {CheckCircleIcon, LockClosedIcon} from "@heroicons/react/24/solid";

const features = [
  {
    title: 'All your Google Accounts',
    description:
      "Have two Google Accounts? Or eight? Need to work across Mail, Calendar, Drive, and Docs for all of them?\n" +
        "Now you can access them all with a single search.",
    image: screenshotAllMyAccounts,
  },
  {
    title: 'Complete Privacy. Built in.',
    description: (<>
      Your data is your business
      <ul className=''>
        <li>
          <LockClosedIcon style={{width: '20px', display: 'inline'}}/> None of your sensitive data ever
          leaves your
          computer
        </li>
        <li>
          <LockClosedIcon style={{width: '20px', display: 'inline'}}/> Government Level AES-256 Encryption
        </li>
        <li>
          <LockClosedIcon style={{width: '20px', display: 'inline'}}/> Google Tier 2 Security CASA
          Verified <CheckCircleIcon className="text-emerald-500 inline-block w-5 h-5"/>
        </li>
      </ul>
    </>),
    image: screenshotPrivacy,
  },
  {
    title: 'Stop Missing Things',
    description:
        "Get to that meeting, regardless of which calendar the invite came to. Find that google docs, regardless of what drive they shared it to.",
    image: screenshotMissingThings,
  },
  {
    title: 'Lighting fast',
    description:
        'Instantly search across all your accounts. And in your accounts: All My Things searches the body of emails and Google docs to find things that you didn\'t have time to put a nice title on.',
    image: screenshotFast,
  },
]

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState('horizontal')

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-blue-600 pb-28 pt-20 sm:py-32 font-main"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Everything you need to manage <br/>your business and personal Google Accounts.
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            Nothing you don't.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
                        selectedIndex === featureIndex
                          ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                          : 'hover:bg-white/10 lg:hover:bg-white/5',
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display text-lg ui-not-focus-visible:outline-none',
                            selectedIndex === featureIndex
                              ? 'text-blue-600 lg:text-white'
                              : 'text-blue-100 hover:text-white lg:text-white',
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm lg:block',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-blue-100 group-hover:text-white',
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        priority
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  )
}
