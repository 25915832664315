import backgroundImage from './backgrounds/background-call-to-action.jpg'
import {Container} from "./Container";
import React from "react";

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Get control of the chaos, today
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            We need multiple google accounts for different reasons, many reasons. It keeps the lights on, but it is a mess. That all stops now.
          </p>
          <a className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-10"
             color="white" variant="solid" href="/get-started" id='get-started-btn-home-page-cta'>
            Get started
          </a>
        </div>
      </Container>
    </section>
  )
}
