import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {Link} from "../util/router";
import Button from "./Button";

const Title = (props) => {
  return (
      <div {...props}>
        <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
          Many Google Accounts{' '}
          <span className="relative whitespace-nowrap text-blue-600">
            <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70"
                preserveAspectRatio="none"
            >
              <path
                  d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"/>
            </svg>
            <span className="relative">made simple</span>
          </span>{' '}

        </h1>
      </div>
  )
}

function HeroSection2(props) {

  return (
    <Section
      size={'none'}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container pb-24">
        <div className="text-center">
          <div className='mx-auto'>
            <Title className='pt-20'/>
            <SectionHeader
                title=''
                subtitle='Unified search, multi-account login, your upcoming events, your important unread emails, quick actions, filters, and so much more.'
                strapline=''
            />

            <Button
                component={Link}
                to="/get-started"
                size="md"
                variant={'new'}
                className={'text-center content-center'}
                style={{margin: '30px auto', width: '300px', display: 'block'}}
                id='get-started-btn-hero'
            >
              Get Started
            </Button>
            <iframe className='mx-auto rounded-xl shadow-lg'
                    width="920" height="517"
                    src="https://www.youtube.com/embed/5ICOWXQKO3g?si=_-rrxgp5yGcchtx0&amp;controls=0"
                    title="All My Things: Demonstration" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>


          <SectionHeader
              title=''
              subtitle={(<>
                All My Things is a secure, private, elegant, and powerful Chrome Extension. It pulls in your Gmail,
                Google Calendar, Google Drive, Google Docs, analyzes them, summarizes them, and makes them all searchable right on your new tab.<br/>And
                it does it locally, privately, and with full encryption.
              </>)}
              strapline={props.strapline}
          />
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
